import React from 'react'
import Project from './Project'

import EquipsImg from '../ProjectScreenshots/EquipsScreenshot.png'
import TBFImg from '../ProjectScreenshots/TBFScreenshot.png'

import { useMediaQuery } from "react-responsive";

const Projects = () => {

    const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });

    let projectsStyle = {
        paddingTop: '20vh',
        backgroundColor: '#ED0F0F',
        display: 'flex',
        flexDirection: 'column'
    }

    const projectsTitleStyle = {
        fontSize: isMobile ? '30vw' : '232px',
        fontFamily: "alternate-gothic-no-2-d,sans-serif",
        fontWeight: "400",
        fontStyle: 'normal',
        color: 'white'
    }

    return (
        <div id="projectsComponent" style={projectsStyle}>
            <div id="projectsTitle" style={projectsTitleStyle}>PROJECTS</div>
            <Project link="https://equips-b027f.firebaseapp.com/" title='Equips' description='A drag and drop equipment system in classic RPG fashion using React DnD' img={EquipsImg}/>
            <Project link="https://turnbasedfighter.firebaseapp.com/" title='Turn Based Fighter' description='A turn based battle game built with React' img={TBFImg} />
        </div>
    )
}

export default Projects;