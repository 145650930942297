import React from 'react'

import { useMediaQuery } from "react-responsive";

const Resume = () => {

    const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });

    const ResumeStyle = {
        backgroundColor: '#ED0F0F',
        fontFamily: "alternate-gothic-no-2-d,sans-serif",
        fontWeight: "400",
        fontStyle: 'normal',
        paddingTop: '20vh',
        paddingBottom: '20vh'
    }

    const ResumeTitleStyle = {
        fontSize: isMobile ? '30vw' : '232px',
        color: 'white'
    }

    const hereStyle = {
        color: '#751b09'
    }

    const ResumeTextStyle = {
        fontSize: isMobile ? '10vw' : '77px',
        margin: '0'
    }

    const linkStyle = {
        textDecoration: 'none'
    }

    return(
        <div id="resumeComponent" style={ResumeStyle}>
            <div id="resumeTitle" style={ResumeTitleStyle}>RESUME</div>
            <p style={ResumeTextStyle}>Click <a style={linkStyle} href="https://drive.google.com/file/d/10Gk6YSNMNqP27gbaeLrATUd-oosVwqPf/view?usp=sharing"><span style={hereStyle}>here</span></a> to download my resume.</p>
        </div>
    )
}

export default Resume