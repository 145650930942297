import React from "react";

import { useMediaQuery } from "react-responsive";

const MainScreen = () => {

  const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });

  let mainScreenStyle = {
    fontFamily: "alternate-gothic-no-2-d,sans-serif",
    fontWeight: "400",
    fontStyle: "normal",
    height: '80vh',
    display: 'flex',
    backgroundColor: '#ED0F0F',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '20vh',
    paddingTop: isMobile ? '10vh' : 0
  };

  let nameGridStyle = {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateRows: "80px 80px"
  };

  let FrancisStyle = {
    fontSize: isMobile ? "30vw" : "275px",
    color: "white",
    margin: 0
  };

  let RaphaelStyle = {
    fontSize: isMobile ? "30vw" : "275px",
    color: "white",
    margin: 0,
    marginTop: isMobile ? '-12vw' : '-120px'
  };

  let JainMobileStyle = {
    color: "white",
    justifySelf: "center",
    fontSize: isMobile ? '30vw' : '275px',
    margin: 0,
    marginTop: isMobile ? '-12vw' : '-120px'
  }

  let logoStyle = {
    gridRowEnd: "span 2",
    width: "135px",
    height: "243px",
    justifySelf: "center",
    alignSelf: "end"
  };

  let nameFlexStyle = {
    display: "flex",
    flexDirection: "column",
  };

  let webDeveloperStyle = {
    fontSize: isMobile ? '10vw' : '77px',
    marginTop: isMobile ? '-8vw' : '-62px'
  }

  return (
    <div id="mainScreenComponent" style={mainScreenStyle}>
        <div style={nameFlexStyle}>
          <p style={FrancisStyle}>FRANCIS</p>
          <p style={RaphaelStyle}>RAPHAEL</p>
          <p style={JainMobileStyle}>JAIN</p>
          <div style={webDeveloperStyle}> WEB DEVELOPER PORTFOLIO</div>
        </div>
    </div>
  );
};

export default MainScreen;
