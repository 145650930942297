import React from "react";

import { useMediaQuery } from "react-responsive";

const Project = ({ title, description, img, link }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });

  let projectTitleBoxStyle = {
    width: isMobile ? "60%" : "463px",
    backgroundColor: "black",
    color: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    fontFamily: "alternate-gothic-no-2-d,sans-serif",
    fontWeight: "400",
    fontStyle: "normal"
  };

  let projectPictureBoxStyle = {
    width: "20%",
    backgroundColor: "green"
  };

  let displayFlex = {
    display: "flex",
    marginTop: isMobile ? "2vw" : "20px",
    justifyContent: "center"
  };

  let projectStyle = {
    fontSize: isMobile ? "12vw" : "93px",
    margin: 0,
    marginLeft: isMobile ? "2vw" : "20px",
    textAlign: "left"
  };

  let projectText = {
    margin: 0,
    fontSize: isMobile ? "5vw" : "39px",
    textAlign: "left",
    marginLeft: isMobile ? "2vw" : "20px"
  };

  let imageStyle = {
    width: "100%",
    height: "100%"
  };

  let mobileStyle = {
    display: "flex",
    flexDirection: "row",
    fontFamily: "alternate-gothic-no-2-d,sans-serif",
    fontWeight: "400",
    fontStyle: "normal",
    marginTop: isMobile ? "2.5vw" : "25px"
  };

  let mobileImageStyle = {
    width: "20vw",
    height: "40vw"
  };

  let mobileProjectHeaderStyle = {
    backgroundColor: "black",
    color: "white",
    width: "70vw",
    height: "40vw"
  };

  let linkStyle = {
    textDecoration: "none"
  };

  return (
    <div style={displayFlex}>
        <a style={linkStyle} href={link}>
          <div style={mobileStyle}>
            <div style={mobileProjectHeaderStyle}>
              <h1 style={projectStyle}>{title}</h1>
              <p style={projectText}>{description}</p>
            </div>
            <img alt="project image" style={mobileImageStyle} src={img} />
          </div>
        </a>
    </div>
  );
};

export default Project;
