import React, { useState } from "react";
import Logo from "../SVGIcons/FrancisLogo.svg";
import HamburgerMenu from "../SVGIcons/HamburgerMenu.svg";

import { useMediaQuery } from "react-responsive";

const Header = () => {

  const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });

  let headerTextStyle = {
    fontSize: isMobile ? '7.2vw' :  "72px",
    textDecoration: 'none'
  };

  let headerFlexStyle = {
    position: "sticky",
    fontFamily: "alternate-gothic-no-2-d,sans-serif",
    fontWeight: "400",
    top: isMobile ? '1vw' : "10px",
    display: "flex",
    width: "100%",
    justifyContent: "space-evenly"
  };

  let logoStyle = {
    marginTop: isMobile ? '2vw' : "20px",
    width: isMobile ? '13.5vw' :  "135px",
    height: isMobile ? '22vw' :  "20vh"
  };

  let mobileLogoStyle = {
    marginTop: isMobile ? '1vw' : "10px",
    width: isMobile ? '13.5vw' : "135px",
    height: isMobile ? '27vw' :  "20vh"
  };

  let hamburgerStyle = {
    width: isMobile ? '7.5vw' : "75px",
    height: isMobile ? '7.5vw' : "75px"
  };

  let hamburgerContainerStyle = {
    width: isMobile ? '7.5vw' : "75px",
    height: isMobile ? '7.5vw' : "75px",
    top: isMobile ? '1vw' : "10px",
    left: isMobile ? '1vw' : "10px",
    position: "absolute",
    zIndex: "100"
  };

  let headerStyle = {
    height: isMobile ? '7.5vw' : "20vh",
    position: "sticky",
    top: 0
  };

  let sideBarStyle = {
    height: "100vh",
    width: "100vw",
    backgroundColor: "#cf1f13"
  };

  let sideBarMenuStyle = {
    display: "flex",
    flexDirection: "column",
    marginTop: '20px'
  };

  let sideBarMenuTextStyle = {
    fontFamily: "alternate-gothic-no-2-d,sans-serif",
    fontWeight: "400",
    fontStyle: 'normal',
    fontSize: isMobile ? '15vw' : '10vh',
    textAlign: 'left',
    margin: 0,
    marginLeft: isMobile ? '1vw' : '10px',
    color: 'black',
    textDecoration: 'none'
  }

  let linkStyle = {
    textDecoration: 'none',
    color: 'black'
  }

  let headerBackground = {
    width: '100%',
    height: '80px',
    backgroundColor: "#cf1f13",
    position: 'absolute',
    zIndex: '-20'
  }

  const [sideBar, setSideBar] = useState(false);

  let closeSidebar = () => {
    setSideBar(!sideBar);
  }

  return (
    <div style={headerStyle}>
      {isMobile && (
        <div
          style={hamburgerContainerStyle}
          onClick={() => {
            setSideBar(!sideBar);
          }}
        >
          <img src={HamburgerMenu} style={hamburgerStyle}></img>
        </div>
      )}
      {sideBar && (
        <div style={sideBarStyle}>
          <div style={headerFlexStyle}>
            <a href="#mainScreenComponent">
            <img
              onClick={() => {
                setSideBar(!sideBar);
              }}
              style={mobileLogoStyle}
              alt="Francis Logo"
              src={Logo}
            ></img>
            </a>
          </div>
          <div style={sideBarMenuStyle}>
            <a onClick={()=>{closeSidebar()}} href="#aboutComponent" style={sideBarMenuTextStyle}>ABOUT</a>
            <a onClick={()=>{closeSidebar()}} href="#projectsComponent" style={sideBarMenuTextStyle}>PROJECTS</a>
            <a onClick={()=>{closeSidebar()}} href="#resumeComponent" style={sideBarMenuTextStyle}>RESUME</a>
            <a onClick={()=>{closeSidebar()}} href="#contactComponent" style={sideBarMenuTextStyle}>CONTACT</a>
          </div>
        </div>
      )}
      <div style={headerFlexStyle}>
        {!isMobile && <div style={headerBackground}></div>}
        {!isMobile && (
          <>
            <div style={headerTextStyle}>
              <a style={linkStyle} href="#aboutComponent">ABOUT</a>
            </div>
            <div style={headerTextStyle}>
              <a style={linkStyle} href="#projectsComponent">PROJECTS</a>
            </div>
          </>
        )}
        <a href="#mainScreenComponent">
        <img
          style={logoStyle}
          alt="Francis Logo"
          src={Logo}
        ></img>
        </a>
        {!isMobile && (
          <>
            <div style={headerTextStyle}>
              <a style={linkStyle} href="#resumeComponent">RESUME</a>
            </div>
            <div style={headerTextStyle}>
              <a style={linkStyle} href="#contactComponent">CONTACT</a>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Header;
