import React from 'react';
import logo from './logo.svg';
import styles from  './App.css';

import MainScreen from './Components/MainScreen'
import About from './Components/About'
import Projects from './Components/Projects'
import Resume from './Components/Resume'
import Contact from './Components/Contact'
import Header from './Components/Header'

function App() {

  let appStyle = {
    backgroundColor: '#ED0F0F',
  }

  return (
    <div style={appStyle} className="App">
      <Header />
      <MainScreen/>
      <About/>
      <Projects />
      <Resume />
      <Contact styles={styles} />
    </div>
  );
}

export default App;
