import React from 'react'

import { useMediaQuery } from "react-responsive";

const About = () => {

    const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });
    
    const aboutStyle = {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '20vh',
        backgroundColor: '#ED0F0F',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '15px',
        marginRight: '15px'
    }

    const aboutTitleStyle = {
        fontSize: isMobile ? '30vw' : '232px',
        fontFamily: "alternate-gothic-no-2-d,sans-serif",
        fontWeight: "400",
        fontStyle: 'normal',
        color: 'white',
    }



    const aboutTextStyle = {
        fontSize: isMobile ? '8vw' : '62px',
        fontFamily: "alternate-gothic-no-2-d,sans-serif",
        fontWeight: "400",
        fontStyle: 'normal',
        margin: 0,
        color: 'black',
        textAlign: 'center'
    }

    const aboutTextBox = {
        width: '700px',
        display: 'flex',
        flexDirection: 'column',
        margin: '0'
    }



  return (
      <div id="aboutComponent" style={aboutStyle}>
        <div style={aboutTitleStyle}>ABOUT</div>
        <div style={!isMobile ?
             aboutTextBox : null}>
            <p style={aboutTextStyle}>My name is Francis Raphael Jain, and I am a Toronto based web developer.</p>
            <p style={aboutTextStyle}>I am currently a student at University of Waterloo, and this is a collection of some of my projects.</p>
        </div>
      </div>
  )
}

export default About;