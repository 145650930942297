import React from "react";

import { useMediaQuery } from "react-responsive";

const Contact = () => {
  let contactStyle = {
    paddingTop: '20vh',
    paddingBottom: '20vh',
    backgroundColor: "#ED0F0F",
    fontFamily: "alternate-gothic-no-2-d,sans-serif",
    fontWeight: "400",
    fontStyle: "normal",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: 'white'
  };

  const contactTitleStyle = {
    fontSize: "232px",
    color: "white"
  };

  const contactTextStyle = {
      fontSize: '77px',
      marginLeft: '20px',
      color: 'black'
  }

  const contactMobileTitleStyle = {
    fontSize: '30vw'
  }

  const contactMobileTextStyle = {
    fontSize: '10vw',
    marginLeft: '15px',
    color: 'black',
  }

  const contactMobileIconStyle = {
    fontSize: '10vw',
    color: 'black'
  }

  const contactIconStyle = {
    fontSize: '77px',
    color: 'black'
  }

  const contactFlex = {
      display: 'flex',
      width: '100%'
  }

  const isMobile = useMediaQuery({ query: "(max-width: 1000px)" });

  return (
    <div id="contactComponent" style={contactStyle}>
      <div style={isMobile ? contactMobileTitleStyle : contactTitleStyle}>CONTACT</div>
      <div>
        <div style={contactFlex}>
          <i style={isMobile ? contactMobileIconStyle : contactIconStyle} class="fas fa-envelope"></i>
          <span style={isMobile ? contactMobileTextStyle : contactTextStyle}>fjralph200@gmail.com</span>
        </div>
        <div style={contactFlex}>
          <i style={isMobile ? contactMobileIconStyle : contactIconStyle} class="fas fa-phone"></i>
          <span style={isMobile ? contactMobileTextStyle : contactTextStyle}>647-572-5246</span>
        </div>
      </div>
    </div>
  );
};

export default Contact;
